.button {
  /* display: inline-block; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  background-color: transparent;
  width: 16vh;
  height: 16vh;
  cursor: pointer;
  outline: none;
}

@media screen and (max-width: 500px) and (orientation: portrait) {
  .button {
    width: 13vh;
    height: 13vh;
  }
}
