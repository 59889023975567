.searchinput {
  width: 100%;
  /* background-color: greenyellow; */
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.searchinput__input {
  padding-left: 1rem;
  width: 7.3rem;
  height: 2.5rem;
  display: inline-block;
  background-color: transparent;
  border: 0 none transparent;
  font-size: 2rem;
  letter-spacing: 1rem;
  border-bottom: 2px solid #333;
  text-align: center;
}
