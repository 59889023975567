.menubutton {
  outline: none;
  width: auto;
  display: flex;
  align-items: stretch;
  /* height: 2rem; */
  display: flex;
  align-items: center;
}

.menubutton__icon {
  height: 5rem;
  width: 5rem;
  margin-right: 1rem;
}

.menubutton__text {
  text-align: left;
  vertical-align: middle;
  height: auto;
  text-decoration: none;
  text-decoration-line: none;
  color: #333;
}

@media screen and (max-width: 1024px) {
}
