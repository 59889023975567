.emojiInput__display {
  position: relative;
  display: flex;
  align-content: stretch;
  margin: 1.5rem 0;
  border: 2px solid #eeebeb;
  border-radius: 1rem;
  padding: 0.7rem;
  min-height: 4.7rem;
}

.emojiInput--readonly .emojiInput__display {
  display: flex;
  align-content: left;
}

.emoji {
  width: 16.6%;
}

.emojiInput__selection {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.emojiInput__selection .emoji {
  width: 100%;
}
