.tile {
  user-select: none;
  height: 100%;
}
.tile img {
  height: 100%;
  user-select: none;
}

.tile--locked {
  height: 20%;
  position: absolute;
}

.tilesvg {
  height: 100%;
  position: relative;
}

.tilesvg__abs {
  position: absolute;
  top: 0%;
  bottom: -10%;
  left: -9%;
  right: -15%;
  z-index: 0;
  height: 145%;
  width: 142%;
}
