.searchmodal {
  z-index: 1001;
  position: absolute;
  height: auto;
  /* top: 40px; */
  left: 40px;
  right: 40px;
  bottom: 40px;
  padding: 1em;
  font-size: 1.15em;
  display: grid;
  column-gap: 10px;
  row-gap: 15px;
}

.searchmodal__overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.searchmodal .searchinput {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.tile0,
.tile1,
.tile2 {
  height: 25vw;
  margin: auto;
}

.tile0 {
  grid-column: 1 / span 2;
  grid-row: 2;
}

.tile1 {
  grid-column: 1 / span 1;
  grid-row: 3;
}

.tile2 {
  grid-column: 2 / span 1;
  grid-row: 3;
}

.tile__notavailable {
  opacity: 0.5;
}
