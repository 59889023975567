.splash {
  background-image: url("/static/background/default.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
}

.splash_blur {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  height: 100vh;
  width: 100vw;
}

.splash_blur img {
  width: 40vw;
}

.splash_blur .name {
  margin-top: 5rem;
  font-size: 2.5rem;
  text-align: center;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.splash_blur .name__sublabel {
  color: rgb(104, 104, 104);
  margin-top: 1rem;
  font-size: 1.7rem;
}

.splash_blur .name__input {
  margin-top: 2rem;
  border: none;
  border-bottom: 3px solid #333;
  background-color: transparent;
  font-size: 2rem;
  outline: none;
  text-align: center;
}

.splash_blur .name__confirm {
  color: #333;
  margin-top: 3rem;
  padding: 0.5rem 2rem;
  border: 3px solid #333;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 2rem;
  outline: none;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 850px) and (orientation: landscape) {
  .splash {
    background-image: url("/static/background/mobile_landscape.svg");
  }

  .splash_blur img {
    width: 50vw;
  }

  .splash_blur .name {
    margin-top: 3rem;
  }

  .splash_blur .name__input {
    margin-top: 1rem;
  }

  .splash_blur .name__confirm {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 500px) and (orientation: portrait) {
  .splash {
    background-image: url("/static/background/mobile_portrait.svg");
  }

  .splash_blur img {
    width: 80vw;
    margin-top: -10vh;
  }

  .splash_blur .name {
    margin-top: 15vh;
  }

  .splash_blur .name__input {
    margin-top: 1rem;
  }

  .splash_blur .name__confirm {
    margin-top: 5rem;
  }
  .splash_blur .name__sublabel {
    margin-top: 2rem;
  }
}
