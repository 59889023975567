.highscoretile {
  /* display: inline-block; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  background-color: transparent;
  width: 28vw;
  height: 28vw;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.highscoretile__icon {
  margin-top: 15%;
  height: 17%;
}
.highscoretile__points {
  font-size: 2.1rem;
  color: #eeebeb;
}
.highscoretile__date {
  height: 2rem;
  vertical-align: center;
  font-size: 0.7rem;
  color: #eeebebc5;
  text-align: center;
  max-width: 60%;
}

@media screen and (max-width: 500px) and (orientation: portrait) {
}
