.newgamemodal {
  z-index: 1001;
  position: absolute;
  height: auto;
  /* top: 40px; */
  left: 40px;
  right: 40px;
  bottom: 40px;
  padding: 1em;
  font-size: 1.15em;
  display: flex;
  flex-direction: column;
  padding: 10%;
}

.newgamemodal__overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.newgamemodal .seperator {
  margin: 1.5rem 0;
}
