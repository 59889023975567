.result {
  background-image: url("/static/background/default.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
}

.result_blur {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  height: 100vh;
  width: 100vw;
}

.result__logoimage {
  width: 40vw;
}

.result_blur .message {
  margin-top: 5rem;
  font-size: 3.5rem;
  text-align: center;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.result_blur .message__sublabel {
  color: rgb(104, 104, 104);
  margin-top: 1rem;
  font-size: 2.2rem;
}

.result_blur .message__actions {
  margin-top: 2rem;
}

.result_blur .action__new,
.result_blur .action__back {
  font-size: 2rem;
}

@media screen and (max-width: 850px) and (orientation: landscape) {
  .result {
    background-image: url("/static/background/mobile_landscape.svg");
  }

  .result__logoimage {
    width: 50vw;
  }

  .result_blur .message {
    margin-top: 3rem;
  }

  .result_blur .message__sublabel {
    margin-top: 1rem;
  }

  .result_blur .message__actions {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 500px) and (orientation: portrait) {
  .result {
    background-image: url("/static/background/mobile_portrait.svg");
  }
  .result__logoimage {
    width: 80vw;
  }
}
