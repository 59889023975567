.Toastify__toast--info,
.Toastify__toast--error,
.Toastify__toast--warning,
.Toastify__toast--success {
  color: #333;
  padding: 1rem !important;
  line-height: 1.2rem;
}

.Toastify__toast--error {
  background-color: #e5726b !important;
}
.Toastify__toast--info {
  background-color: #fbe7d9 !important;
}
.Toastify__toast--warning {
  background-color: #e5a66b !important;
}
.Toastify__toast--success {
  background-color: #6be586 !important;
}
