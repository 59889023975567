.colorbutton {
  position: relative;
}

.colorbutton .button {
  position: relative;
  display: inline-block;
  z-index: 2;
}

.colorTile {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.colorbutton .colorTile {
  position: absolute;
  z-index: 1;
  top: 5.5vh;
  left: 5.5vh;
  width: 5vh;
  height: 5vh;
  overflow: hidden;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}

.colorbutton.expanded .colorTile.Yellow {
  transform: translate(-6vh, 12vh);
}

.colorbutton.expanded .colorTile.Red {
  transform: translate(0vh, 17vh);
}

.colorbutton.expanded .colorTile.Purple {
  transform: translate(9vh, 6vh);
}

.colorbutton.expanded .colorTile.Blue {
  transform: translate(0vh, 11vh);
}

.colorbutton.expanded .colorTile.Green {
  transform: translate(-9vh, 6vh);
}

.colorbutton.expanded .colorTile.Grey {
  transform: translate(6vh, 12vh);
}

@media screen and (max-width: 500px) and (orientation: portrait) {
  .colorbutton .colorTile {
    top: 4vh;
    left: 4vh;
  }

  .colorbutton.expanded .colorTile.Yellow {
    transform: translate(-6vh, 12vh);
  }

  .colorbutton.expanded .colorTile.Red {
    transform: translate(0vh, 17vh);
  }

  .colorbutton.expanded .colorTile.Purple {
    transform: translate(9vh, 6vh);
  }

  .colorbutton.expanded .colorTile.Blue {
    transform: translate(0vh, 11vh);
  }

  .colorbutton.expanded .colorTile.Green {
    transform: translate(-9vh, 6vh);
  }

  .colorbutton.expanded .colorTile.Grey {
    transform: translate(6vh, 12vh);
  }
}
