.bm-item-list {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 10rem;
  align-items: center;
}

.packageversion {
  position: absolute;
  bottom: 0.5rem;
  font-size: 1rem;
  left: 0;
  right: 0;
  text-align: center;
  color: rgb(104, 104, 104);
}
.menu__link {
  text-decoration: none;
}
