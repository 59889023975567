.searchbutton {
  position: relative;
}

.searchbutton .button {
  position: relative;
  z-index: 2;
}

.searchbutton .inputbutton {
  position: absolute;
  z-index: 1;
  top: 6vh;
  left: 8vh;
  width: 1rem;
  min-height: 1rem;
  overflow: hidden;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}

.searchbutton.expanded .inputbutton {
  width: 9rem;
  -webkit-transform: translate(2rem, 0);
  -moz-transform: translate(2rem, 0);
  -o-transform: translate(2rem, 0);
  -ms-transform: translate(2rem, 0);
  transform: translate(2rem, 0);
}
