.highscores {
  max-height: 100vh;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.highscores__back {
  display: block;
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
}

.highscores__title {
  margin-top: 15%;
  text-align: center;
  font-weight: 400;
  color: #333;
}

.highscores__list {
  position: relative;
  width: 100%;
  overflow: auto;
}

@media screen and (max-width: 500px) and (orientation: portrait) {
}
