.game {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.game__gameboard {
  height: 40vw;
  width: 50vw;
  margin-left: 20vw;
}

.game__gameboard .tile.tile--dragged {
  height: calc(40vw * 0.2);
}

.game__dndWrapper {
  display: flex;
  align-items: center;
}
.game__logoimage {
  display: none;
}

@media screen and (max-width: 500px) and (orientation: portrait) {
  .game__gameboard {
    order: 1;
    height: 100vw;
    margin-left: 0;
  }
  .game__dndWrapper {
    align-items: start;
  }

  .game__gameboard .tile.tile--dragged {
    height: calc(100vw * 0.2);
  }

  .game__actionbar {
    order: 2;
  }
  .game__dndWrapper {
    flex-direction: column;
  }
  .game__logoimage {
    width: 80vw;
    display: block;
  }
}
