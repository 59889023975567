/* .tiledropzoneSvg {
  height: 100%;
} */

.tiledropzone {
  height: 20%;
}

.tiledropzone__svg {
  z-index: 0;
}
.tiledropzone__ref {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  height: 100%;
  width: 100%;
}
