.actionbar {
  display: flex;
  flex-direction: column;
  width: 25vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
  height: 80vh;
  justify-content: space-around;
}

.actionbar .logo {
  height: 15vh;
  /* margin-bottom: 5vh; */
}

.actionbar__search,
.actionbar__menu {
  display: none;
}

@media screen and (max-width: 500px) and (orientation: portrait) {
  .actionbar {
    padding: 0;
    height: auto;
    width: 90vw;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15vw;
  }
  .actionbar__reset,
  .actionbar__select,
  .actionbar__theme {
    display: none;
  }

  .actionbar__search,
  .actionbar__random,
  .actionbar__menu {
    display: block;
  }
}
