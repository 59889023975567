.multiplayerclientmodal {
  z-index: 1001;
  position: absolute;
  height: auto;
  /* top: 40px; */
  left: 40px;
  right: 40px;
  bottom: 40px;
  padding: 7%;
  font-size: 1.15em;
  display: flex;
  flex-direction: column;
}

.multiplayerclientmodal__overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.multiplayerclientmodal__logo {
  margin: auto;
  margin-bottom: 3rem;
  width: 35%;
}

.multiplayerclientmodal__text {
  display: block;
  padding: 0 1.5rem;
  text-align: center;
}
