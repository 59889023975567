.inputbutton {
  border: 3px solid #9d9696;
  background-color: white;
  outline: none;
  width: auto;
  padding: 0.2rem 0.3rem 0.2rem 3rem;
  display: flex;
  align-items: stretch;
  /* height: 2rem; */
}

.inputbutton__input {
  border: none;
  font-family: monospace;
  width: 2.5rem;
  font-size: 1.5rem;
  height: 1.5rem;
  background-color: transparent;
  color: #9d9696;
}

.inputbutton__button {
  width: 1rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: white;
  flex: 1;
  color: #9d9696;
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .inputbutton {
    padding-left: 4rem;
  }
}
