*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", monospace;
}

html,
body {
  padding: 0;
  margin: 0;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  font-size: 16px;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 14px;
  }
}
