.actionbutton {
  display: block;
  color: #333;
  margin: 2rem auto 1rem;
  padding: 0.5rem 2rem;
  border: 2px solid #eeebeb;
  border: 2px solid #333;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 1.3rem;
  outline: none;
  text-align: center;
  cursor: pointer;
  width: auto;
}
